import { httpGet, httpPost } from '@yimoko/store';

// 获取活动分类
export const getActivityCategory = () => httpGet<ApiServiceOperationsActivityCategoryBFFDetailReply[]>('/service/operations/bff/activityCategory/getAllCache');

// 获取Flagship Initiatives分类和它的子分类
export const getFlagshipInitiativesCategory = async () => {
  const res = await getActivityCategory();
  const { data } = res;
  const flagshipInitiatives = data.find(item => item?.extra?.name === 'Flagship Initiatives');
  const subCategories = data.filter(item => item.parentID === flagshipInitiatives?.id) ?? [];
  return {
    ...res,
    data: {
      ...flagshipInitiatives,
      children: subCategories,
    },
  };
};
// 获取国际会议与活动列表
export const getActivityList = (params: ActivityListParams) => httpPost<ActivityListResponse>('/service/operations/bff/activity/list', params);

export type ActivityListParams = {
  page?: number;
  pageSize?: number;
};

/**
 * api.service.operations.ActivityBFFListReply
 */
export interface ActivityListResponse {
  count?: string;
  data?: ApiServiceOperationsActivityBFFRowsItem[];
  page?: number;
  pageSize?: number;
  totalPages?: number;
  [property: string]: any;
}

/**
* api.service.operations.ActivityBFFRowsItem
*/
export interface ApiServiceOperationsActivityBFFRowsItem {
  /**
   * 分类数据
   */
  activityCategories?: ApiServiceOperationsActivityCategoryBFFDetailReply[];
  /**
   * 分类
   */
  categoryIDs?: string[];
  /**
   * 封面
   */
  cover?: string;
  /**
   * 国际化封面
   */
  coverLocal?: string;
  /**
   * 创建时间
   */
  createTime?: string;
  /**
   * 结束日期
   */
  endDate?: string;
  /**
   * 扩展信息
   */
  extra?: string;
  /**
   * 国际化扩展信息
   */
  extraLocal?: string;
  /**
   * ID
   */
  id: string;
  /**
   * 开始日期
   */
  startDate?: string;
  /**
   * 简介
   */
  summary?: string;
  /**
   * 国际化简介
   */
  summaryLocal?: string;
  /**
   * 标题
   */
  title?: string;
  /**
   * 国际化标题
   */
  titleLocal?: string;
  /**
   * 更新时间
   */
  updateTime?: string;
  [property: string]: any;
}

/**
* api.service.operations.ActivityCategoryBFFDetailReply
*/
export interface ApiServiceOperationsActivityCategoryBFFDetailReply {
  /**
   * 封面
   */
  cover?: string;
  /**
   * 国际化封面
   */
  coverLocal?: string;
  /**
   * 创建时间
   */
  createTime?: string;
  /**
   * 扩展信息
   */
  extra?: Record<string, any>;
  /**
   * 国际化扩展信息
   */
  extraLocal?: string;
  /**
   * ID
   */
  id: string;
  /**
   * 名称
   */
  name?: string;
  /**
   * 国际化名称
   */
  nameLocal?: string;
  /**
   * 父级ID
   */
  parentID?: string;
  /**
   * 简介
   */
  summary?: string;
  /**
   * 国际化简介
   */
  summaryLocal?: string;
  /**
   * 更新时间
   */
  updateTime?: string;
  [property: string]: any;
}


// 获取活动详情
export const getActivityDetail = (id: string) => httpGet<ActivityDetailResponse>('/service/operations/bff/activity/getCache', {
  params: { id },
});

export type ActivityDetailResponse = ApiServiceOperationsActivityBFFRowsItem;

export interface ActivityBatchDetailResponse {
  data: ApiServiceOperationsActivityBFFRowsItem[];
}
