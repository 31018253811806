import { setValidateLanguage } from '@formily/core';
import { observer } from '@formily/react';
import { debounce } from 'lodash-es';
import { ChevronUp, Search } from 'lucide-react';

import { Dispatch, MutableRefObject, SetStateAction, useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { useMount } from 'react-use';

import { getFlagshipInitiativesCategory } from '@/api/activity';
import { Button } from '@/components/ui/button';
import { HoverMenu, HoverMenuProps } from '@/components/ui/hover-menu';
import { CosImage } from '@/components/ui/image';
import { Input } from '@/components/ui/input';
import { Visible } from '@/components/ui/visible';
import { useSWR } from '@/hooks/swr';
import { useLocale, useSupportedLocales } from '@/i18n';

import { cn } from '@/lib/utils';
import { useRouter } from '@/navigation';
import { setPersistence } from '@/utils/persistence';


// 用于保存useState的值，并且可以通过ref获取到最新的值
function useStateWithRef<S = undefined>(): [S | undefined, Dispatch<SetStateAction<S | undefined>>, MutableRefObject<S | undefined>];
function useStateWithRef<S>(initialState: S | (() => S)): [S, Dispatch<SetStateAction<S>>, MutableRefObject<S>];
function useStateWithRef<S>(initialState?: S | (() => S)) {
  const [value, setValue] = useState(initialState);
  const ref = useRef(value);
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return [value, setValue, ref];
}

export const Header = observer(() => {
  const { t, i18n } = useTranslation('Header');
  const supportedLocales = useSupportedLocales();
  const { data: flagshipInitiativesCategory } = useSWR({
    api: async () => {
      const response = await getFlagshipInitiativesCategory();
      const flagshipInitiativesCategory = response?.data ?? [];
      setPersistence('flagshipInitiativesCategory', flagshipInitiativesCategory);
      return response;
    },
  }, undefined, {
    key: 'flagshipInitiativesCategory',
  });
  const bottomNavs: HoverMenuProps['menus'][] = [
    {
      trigger: 'smart education platform',
      href: '/smart-education-platform',
      items: [],
    },
    {
      trigger: 'news center',
      href: '/news-center/home',
      items: [],
    },
    flagshipInitiativesCategory?.children ? {
      trigger: flagshipInitiativesCategory?.name,
      items: flagshipInitiativesCategory?.children?.map(item => ({
        title: item.name,
        href: `/activity/list?category=${item.id}`,
      })) ?? [],
    } : undefined,
    // {
    //   trigger: 'knowledge and value',
    //   items: [
    //     // {
    //     //   title: 'database',
    //     //   href: '/knowledge-and-value/database/list',
    //     // },
    //   ],
    // },
    {
      trigger: 'partners',
      href: '/partners/home',
      items: [],
      // items: [
      //   {
      //     title: 'higher education institutions and international organizations',
      //     href: '/partners/higher-eiaio',
      //   },
      //   {
      //     title: 'corporate partners',
      //     href: '/partners/corporate-partners',
      //   },
      // ],
    },
    {
      trigger: 'about us',
      href: '/about-us',
      items: [],
    },
  ].filter(Boolean).map((nav: any) => ({
    ...nav,
    trigger: t(nav.trigger),
    items: nav?.items.map((item: any) => ({
      ...item,
      title: t(item.title),
    })),
  }));
  // 控制搜索框的显示与隐藏
  const [searchVisible, setSearchVisible, searchVisibleRef] = useStateWithRef(false);
  const [keyword, setKeyword] = useState('');
  // 获取当前路由
  const router = useRouter();
  // 获取当前语言
  const locale = useLocale();
  useMount(() => {
    setValidateLanguage(locale);
    const subscribeCallback = (e: any) => {
      // 判断点击的是否是search-icon
      const searchIcon = document.querySelector('#search-icon');
      if (searchVisibleRef?.current && searchIcon?.contains(e?.target)) {
        return;
      }
      // 判断用户点击的是否是搜索框
      if (searchVisibleRef?.current && !document.querySelector('#search-box')?.contains(e?.target)) {
        setSearchVisible(false);
      }
    };
    document.addEventListener('click', subscribeCallback);
    return () => {
      document.removeEventListener('click', subscribeCallback);
    };
  });
  const navTriggerClass = 'flex items-center text-[15px] cursor-pointer border border-solid border-transparent hover:border-b-[#fff] box-border whitespace-nowrap';
  const menuItemClass = 'text-center items-center justify-center block flex-1 text-[15px]';
  return (
    <header className={cn(
      'sticky top-0 left-0 right-0 z-10 bg-main flex flex-col items-center justify-center',
      searchVisible && 'bg-main',
    )} id='header'>
      <div className="py-[17px] text-white flex" id='header-content'>
        <div className="flex items-center mr-[89px] cursor-pointer" onClick={() => {
          router.push('/');
        }}>
          <CosImage
            src="/global/header logo@2x.png" // 替换为你的logo路径
            alt="logo"
            width={121}
            height={96}
            className="mr-[31px] w-[121px] h-[96px]"
            useLoading={false}
          />
          <h1 className="text-[17px]">{t('title')}</h1>
        </div>
        <nav className="flex flex-col items-end">
          <div className='flex mb-[20px] items-center justify-center gap-[10px]'>

            <div className='flex justify-center items-center'>
              {
                supportedLocales.map((l, i) => (
                  <div key={l.href} onClick={() => {
                    if (locale === l.href) return;
                    document.documentElement.lang = l.href;
                    setValidateLanguage(l.href);
                    i18n.changeLanguage(l.href);
                  }} className={cn('flex items-center justify-center text-[15px] cursor-pointer hover:underline text-[#fbfbfb]', l.href === locale && 'font-bold text-white')}>
                    {l.title}
                    <div className='px-[5px] text-white'>
                      {i !== supportedLocales.length - 1 && '|'}
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
          <div className="flex items-center justify-center gap-[30px]">
            {
              bottomNavs.map((nav, key) => (
                <HoverMenu key={key} menus={nav} triggerProps={{ className: navTriggerClass }} menuItemProps={{
                  className: menuItemClass,
                }} activeMenuItemProps={{
                  className: cn(menuItemClass, 'text-black'),
                }} activeTriggerProps={{
                  className: cn(navTriggerClass, 'text-black'),
                }} />
              ))
            }
            <div className="flex items-center cursor-default hover:border-b-[1px] ml-[10px]" id='search-icon' onClick={debounce(() => setSearchVisible(!searchVisible), 300, { leading: true })}>
              <Search className="w-[18px] h-[18px]" />
            </div>
          </div>
        </nav>
      </div>
      <Visible visible={searchVisible}>
        <search className="flex items-center justify-center w-full border-t-[#fff] border-t-[1px] h-[150px] relative" id='search-box'>
          <div className="flex items-center justify-center border-b-[2px] border-b-[#fff] py-[7px]">
            <CosImage src="/home/icon_search@2x.png" alt="Search" width={36} height={36} className="w-[36px] h-[36px]" />
            <Input placeholder={t('search placeholder')} className="w-[220px] border-none outline-none bg-transparent focus-visible:ring-0 focus-visible:ring-ring focus-visible:ring-offset-0 placeholder:text-[#fff] text-[#fff] text-base" value={keyword} onChange={e => setKeyword(e?.target?.value)} />
            <Button className="bg-transparent rounded-[2px] text-white text-base border-[1px] border-white w-[90px] h-[38px]" variant='outline' onClick={() => {
              if (!keyword) return;
              setSearchVisible(false);
              router.push(`/search?titleLike=${keyword}`);
            }} disabled={!keyword}>{t('search')}</Button>
          </div>
          <ChevronUp className="absolute bottom-0 left-0 right-0 m-auto text-white cursor-pointer w-[18px] h-[18px]" onClick={debounce(() => setSearchVisible(false), 300)}>关闭</ChevronUp>
        </search>
      </Visible>
    </header >
  );
});
