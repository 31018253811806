
import { ArrowRight } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import { CosImage } from '@/components/ui/image';
import { cn } from '@/lib/utils';
import { Link } from '@/navigation';

type HrefType =
  | 'http' // http 协议的链接
  | 'https' // https 协议的链接
  | 'mailto' // 邮件地址
  | 'tel' // 电话号码
  | 'sms' // 短信
  | '#'; // 锚点
export const Footer = () => {
  const { t } = useTranslation('Footer');
  const list: Array<string | {
    title: string;
    href: string;
    hrefType?: HrefType;
  }> = t('list', { returnObjects: true });
  return (
    <footer className="bg-main" id='footer'>
      <div className="flex ml-[196px] py-[83px] items-center">
        <CosImage src="/global/footer logo@2x.png" width={269} height={194} alt="logo" />
        <div className="w-[1px] h-[128px] bg-[#fff] mx-[30px]"></div>
        <div className="flex flex-col gap-[14px] text-lg">
          {/* <div className="flex items-center">
            <CosImage src="/home/telphone@2x.png" width={20} height={20} alt="telphone" className="mr-[8px]" />
            <span className="text-[#fff] ml-[10px]">{t('telphone')}</span>
          </div>
          <div className="flex items-center">
            <CosImage src="/home/postcode@2x.png" width={20} height={20} alt="telephone" className="mr-[8px]" />
            <span className="text-[#fff] ml-[10px]">{t('postcode')}</span>
          </div>
          <div className="flex items-center">
            <CosImage src="/home/email@2x.png" width={20} height={20} alt="email" className="mr-[8px]" />
            <span className="text-[#fff] ml-[10px]">
              <a href="mailto:" className="text-[#fff]">{t('email')}</a>
            </span>
          </div> */}
          {/* <div className="text-base">
            <span className="text-[#fff]">{t('address')}</span>
          </div> */}
          {
            list.map((item, key) => {
              if (typeof item === 'string') {
                return (
                  <div key={key} className="text-white">{item}</div>
                );
              }
              return (
                <div key={key} className="flex items-center text-white">
                  <span className="mr-[10px]">{item.title}</span>
                  <a href={item.hrefType ? `${item.hrefType}:${item.href}` : item.href} className="hover:underline">{item.href}</a>
                </div>
              );
            })
          }
        </div>
        <div className='px-[50px]'>
          <Link className={cn('flex items-center justify-between gap-[12px] py-[7px] px-[18px] w-max bg-white text-main rounded-[20px] text-[14px] hover:bg-white')} href='/feedback-form'>
            <span className="text-base font-bold">{t('apply now')}</span>
            <ArrowRight width={20} height={20} className={cn('')} />
          </Link>
        </div>
      </div>

    </footer>
  );
};
