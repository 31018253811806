import { get, set } from 'lodash-es';
import { useEffect, useState } from 'react';

export const NAME = 'persistence-storage-event';

// 根据key获取全局数据，key为undefined时,从localStorage中获取
export function getPersistence<T extends Window['persistence'], K extends keyof Window['persistence']>(key: K, defaultValue?: T[K]): T[K] {
  // 先判断window上是否有对应的key
  const mermoryValue = get(window, `persistence.${key}`);
  if (mermoryValue !== undefined) {
    return mermoryValue;
  }
  const persistValue = localStorage.getItem(key);
  if (persistValue === null || persistValue === undefined) {
    return defaultValue as T[K];
  }
  return JSON.parse(persistValue);
}

// 设置全局数据
export function setPersistence<T = any>(key: string, value: T) {
  set(window, `persistence.${key}`, value);
  localStorage.setItem(key, JSON.stringify(value));
  window.dispatchEvent(new StorageEvent(NAME));
}

// hooks版本
export function usePersistentState<T>(key: string, defaultValue: T): [T, (value: T) => void] {
  const [state, setState] = useState(() => getPersistence(key, defaultValue));
  useEffect(() => {
    setPersistence(key, state);
  }, [key, state]);
  return [state, setState];
}

// 监听全局数据变化
export function usePersistence<T extends Window['persistence'], K extends keyof Window['persistence']>(key: K, defaultValue?: T[K]): T[K] {
  const [state, setState] = useState(() => getPersistence(key, defaultValue));
  useEffect(() => {
    const listener = () => {
      setState(getPersistence(key, defaultValue));
    };
    window.addEventListener(NAME, listener);
    return () => {
      window.removeEventListener(NAME, listener);
    };
  }, [key, defaultValue]);
  return state;
}

