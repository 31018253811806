import React from 'react';
import { useTranslation } from 'react-i18next';

import { cn } from '@/lib/utils';


import { Button } from './button';
import { CosImage } from './image';

// 404
export const ErrorNotFound = ({ className, title }: { className?: string, title?: string }) => {
  const { t } = useTranslation('Global');
  return (
    <div className='flex flex-col min-h-[300px]'>
      <div className='bg-white flex-1 flex items-center justify-center'>
        <div className={cn('flex flex-col items-center justify-center text-[#B8B8B8] text-sm m-auto w-full h-full', className)}>
          <div className="mb-[7px]">
            <CosImage src="/global/empty data@2x.png" alt="Empty" width={90} height={90} className="w-[90px] h-[90px]" />
          </div>
          <span className="line-clamp-2 text-center">{title ?? t('notFound')}</span>
        </div>
      </div>
    </div >
  );
};

export const UncatchError = ({ className, title }: { className?: string, title?: string }) => {
  const { t } = useTranslation('Global');
  return <div className={cn('flex flex-col items-center justify-center text-[#B8B8B8] text-sm w-full h-screen m-auto', className)}>
    <span className="line-clamp-2 text-center">{title ?? t('uncatch error')}</span>
    <Button onClick={() => window.location.replace('/')} className="mt-[20px]">{t('back to home')}</Button>
  </div>;
};


export const StatusError = ({ statusCode, className, children, title, hasData, loading }: { statusCode?: number, className?: string, title?: string, children: React.ReactNode, hasData: boolean, loading?: boolean }) => {
  const { t } = useTranslation('Global');
  if (loading) {
    return null;
  }
  if ((statusCode === 200 || !statusCode) && hasData) {
    return <>{children}</>;
  }
  return (
    <div className={cn('flex flex-col items-center justify-center text-[#B8B8B8] text-sm w-[300px] m-auto py-[30px]', className)}>
      <CosImage src="/global/empty data.png" alt="Empty" width={90} height={90} className="w-[90px] h-[90px] mb-[15px]" />
      <span className="line-clamp-2 text-center">{title ?? t('no data')}</span>
    </div>
  );
};

export const LazyLoading = ({ className }: { className?: string }) => (
  <div className={cn('flex flex-col items-center justify-center text-[#B8B8B8] text-sm w-full h-screen m-auto', className)}>
    <CosImage src="/global/loading.gif" alt="Loading" width={90} height={90} className="w-[90px] h-[90px]" useLoading={false} />
    <span className="line-clamp-2 text-center">Loading...</span>
  </div>
);
