import { createSchemaField } from '@formily/react';
import { ConfigStoreProvider, SchemaFieldProvider } from '@yimoko/store';

import { Suspense, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { useMount } from 'react-use';

import VConsole from 'vconsole';

import { components } from '@/components/index';
import { UncatchError, LazyLoading } from '@/components/ui/status';
import { usePageTracking } from '@/hooks/window';
import { BaseLayout } from '@/layout/base';
import { RootRouter } from '@/router';
import { InitConfig, configStore } from '@/stores/config';

import { initI18n } from './i18n';

const SchemaField = createSchemaField({ components });

function InitLocales({ children }: { children: React.ReactNode }) {
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  useMount(() => {
    if (window.location.search.includes('vConsole=true')) {
      new VConsole();
    }
    initI18n().finally(() => {
      setLoading(false);
    });
  });
  return loading ? <LazyLoading /> : <>{children}</>;
}

function App() {
  usePageTracking(() => {
    window.scrollTo(0, 0);
  });
  return (
    <ErrorBoundary fallback={<UncatchError />}>
      <Suspense fallback={<LazyLoading />}>
        <InitLocales>
          <ConfigStoreProvider value={configStore} >
            <SchemaFieldProvider value={SchemaField}>
              <InitConfig>
                <BaseLayout>
                  <RootRouter />
                </BaseLayout>
              </InitConfig>
            </SchemaFieldProvider>
          </ConfigStoreProvider>
        </InitLocales>
      </Suspense>
    </ErrorBoundary>
  );
}

export default App;
