/* eslint-disable complexity */
import { IBaseStoreConfig, IStoreValues, useBaseStore } from '@yimoko/store';

import { isEqual } from 'lodash-es';
import { useLayoutEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useUnmount, useMount } from 'react-use';


export type FETCH_ITEM = {
  key?: string,
  fnArgs?: Record<string, any>,
  response: any,
  loading: boolean,
  language: string,
  startAt: number,
};

export const FETCH = new Map<any, FETCH_ITEM>();

export type SwrOptions<R> = {
  key: string,
  when: () => boolean,
  defaultData: R,
};

function getKey<V extends object = IStoreValues, R extends object = any>(config: IBaseStoreConfig<V, R>, option?: Partial<SwrOptions<R>>) {
  return option?.key ?? config.api?.name;
}
// 简单版本的useSWR，离开页面的时候会自动清除缓存
export const useSWR = <V extends object = IStoreValues, R extends object = any>(config: IBaseStoreConfig<V, R>, fnArgs: Record<string, any> = {}, option?: Partial<SwrOptions<R>>) => {
  const { loading, ...rest } = useBaseStore({ ...config });
  const { i18n } = useTranslation();
  const unmountedRef = useRef(false);

  useLayoutEffect(() => {
    const onRevalidate = () => {
      if (unmountedRef.current) return;
      const key = getKey(config, option);
      const fetch = FETCH.get(key);
      const isSameFnArgs = isEqual(fetch?.fnArgs, fnArgs);
      const isSameLanguage = fetch?.language === i18n.language;
      const whenStatus = option?.when?.() ?? true;
      if (!whenStatus) return;
      if (!fetch || !isSameFnArgs || !isSameLanguage) {
        FETCH.set(key, { fnArgs, response: rest?.response, loading, language: i18n.language, startAt: Date.now() });
        rest.runAPIByValues(fnArgs as V ?? {});
        return;
      }
    };
    onRevalidate();
  });
  useMount(() => {
    unmountedRef.current = false;
  });
  useUnmount(() => {
    unmountedRef.current = true;
    const key = getKey(config, option);
    FETCH.delete(key);
  });
  const { response } = rest;
  let data = response?.data ?? option?.defaultData ?? undefined;
  if (response?.status !== 200) {
    data = option?.defaultData;
  }
  return {
    loading,
    ...rest,
    data: data ?? undefined,
    error: {
      statusCode: response?.status,
      statusText: response?.statusText,
    },
  };
};
